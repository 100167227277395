import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css'; // Import CSS for Header component
import logo from './edisonLogo.jpg';

const Header = () => {
    return (
        <header className="header">
            <div style={{ paddingBottom: '20px', textAlign: 'center' }}>
                บริษัท เอดิสันแมคคานิค จำกัด / โทร. 083-0192466 / edison.salesmechanic@gmail.com / FB: facebook.com/edisonmechanic
            </div>
            <div className="logo-text-container" style={{paddingLeft: '10px'}}>
                <div className="column" style={{width: '100px'}}>
                    <img src={logo} alt="Logo" className="logo-image" />
                </div>
                <div className="column" style={{width: '100%'}}>
                    <span>รับซ่อม Overhaul เกียร์ มอเตอร์ ชลบุรี</span>
                </div>
            </div>
            <nav style={{paddingLeft: '10px', textAlign: 'center'}}>
                <ul>
                    <li>
                        <NavLink className="navFontSize" end to="/" style={({ isActive }) => ({ color: isActive ? 'yellow' : 'white' })}>
                            หน้าหลัก
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="navFontSize"  to="/products" style={({ isActive }) => ({ color: isActive ? 'yellow' : 'white' })}>
                            สินค้า
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="navFontSize"  to="/services" style={({ isActive }) => ({ color: isActive ? 'yellow' : 'white' })}>
                            บริการ
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="navFontSize"  to="/about" style={({ isActive }) => ({ color: isActive ? 'yellow' : 'white' })}>
                            ผลงาน
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="navFontSize"  to="/contact" style={({ isActive }) => ({ color: isActive ? 'yellow' : 'white' })}>
                            ติดต่อเรา
                        </NavLink>
                    </li>
                    {/* Add other navigation links */}
                </ul>
            </nav>
        </header>
    );
};

export default Header;
