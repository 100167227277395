import React from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import './Contact.css';
import branch from "./images/branch.png";
import mapPic from "./images/mapPic.png";
import qrHeadBranch from './images/qrHeadBranch.png';
import mapFactory from "./images/mapFactory.png";
import qrMapFactory from "./images/qrMapFactory.png";

const Contact = () => {
    return (
        <div className="contact">
            <Header />
            <div>
                <h2 className="branch1"> สาขา กทม. และ ชลบุรี </h2>
            </div>
            <div style={{ padding: '5px'}}>
                <img className="responsive-image" src={branch} alt="branch" />
            </div>
            <div className="dashed-line"></div>
            <div>
                <h2 className="branch1"> สำนักงานใหญ่ </h2>
                <h2 className="branch2"> 717/22 ห้องเลขที่ 1A ชั้น 1 ซอนชานเมือง แขวงดินแดง เขตดินแดง กรุงเทพฯ 10400 </h2>
            </div>
            <div>
                <img src={mapPic} alt="mapPic" style={{width: "100%"}}/>
            </div>
            {/*<div className="dashed-line" style={{paddingBottom: "10px"}}></div>*/}
            <div className="" style={{ textAlign: "center", paddingBottom: "25px"}}>
                <h2 className="branch1"> Google Map Location</h2>
                <img className="imgMap" src={qrHeadBranch} alt="qrHeadBranch" />
            </div>
            <div className="dashed-line" style={{paddingBottom: "10px"}}></div>
            <div className="factorySection" style={{textAlign: "center", alignItems: "center"}}>
                <div>
                    <h2 className="branch1">
                        โรงงาน:
                        27/30 ตําบลหนองตําลึง อําเภอพานทอง ชลบุรี 20160
                    </h2>
                </div>
                <img src={mapFactory} alt="mapFactory" className="responsive-image"/>
            </div>
            <div className="mapContainerSection" style={{ alignItems: "center"}}>
                <div className="mapSection">
                    <div>
                        <h2 className="branch1">1. เดินทางจาก มอเตอร์เวย์อมตะนคร </h2>
                        <h3 className="branch1"> ออกจากมอเตอร์เวย์ จากนั้นขับผ่าน CJ Supermarket, ตลาดรถเมย์ หนองตำลึง, บริษัท บี.เอ็น.เค จนถึงปั๊มน้ำมันเชลล์ ให้กลับรถยูเทินหลังปั๊มน้ำมัน ตรงมาเรื่อยๆ
                            ฝั่งซ้ายจะเป็นเชื่อมเลเซอร์ชลบุรีให้ชลอ แล้วเลี้ยวซ้ายซอยงามเจริญ ตรงมาอีกนิด บริษัทเอดิสันเมคคานิค จำกัด จะอยู่ทางขวามือ
                        </h3>
                    </div>
                    <h2 className="branch1">2. เดินทางจาก 4 แยกมาบโป่ง </h2>
                    <h3 className="branch1">
                        ขับรถตรง มุ่งหน้ามาทาง มอเตอร์เวย์ ตรงมาเรื่อยๆ ฝั่งซ้ายจะเป็นเชื่อมเลเซอร์ชลบุรี ให้ชลอ แล้วแล้วซ้าย ซอยงามเจริญ ตรงมาอีกนิด
                        บริษัทเอดิสันแมคคานิค จำกัด จะอยู่ทางขวามือ
                    </h3>
                </div>
                <div className="mapSection">
                    <h2 className="branch1"> Google Map Factory Location</h2>
                    <img src={qrMapFactory} alt="qrMapFactory" style={{width: "40%"}}/>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Contact;
