import React from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./About.css";
import ReactPlayer from "react-player";
import working from "./images/working.png";

const linkYoutube = [
    'https://youtube.com/shorts/9F5BKh3ewJM?si=YFDkTeu88Z7SaSgH',
    'https://youtube.com/shorts/IZFFuvHRXt8?si=ji55R7otHYvcrKdd',
    'https://youtube.com/shorts/T7F2AEaIp7U?si=W7PeFQjqe8BQ1pwy',
    'https://youtube.com/shorts/-uOwdQFqGzc?si=70kJLaIwkJj0elj3',
    'https://youtube.com/shorts/yinQlZWLjiI?si=ipFkwyYho0_R_P8x',
    'https://youtube.com/shorts/jALyjoev2Os?si=5k8rDnr2fvUApPpX',
    'https://youtube.com/shorts/Bzlzb_Cyru8?si=tZeu9nESXXFvVB9Q',
    'https://youtube.com/shorts/Czreu0i4GfY?si=5sC7nIRMp1z4Ca5J',
    'https://youtube.com/shorts/t26y9bLyDWo?si=7hZ5n0GQhKcQYBwQ',
    'https://youtube.com/shorts/jDO_NsHz4zk?si=R6dEW2jS8Xc6VkLg',
    'https://youtube.com/shorts/3k_njgdxSNk',
    'https://youtube.com/shorts/QFt_rsAzfUU'
]

const About = () => {
    return (
        <div className="about">
            <Header />
            <div>
                <h2 className="channelYoutube"> ติดตามได้ที่ช่อง youtube channel: @EDISONMECHANIC </h2>
            </div>
            <div className='video-container'>
                {linkYoutube.map((url, index) => (
                    <div className='video-item' key={index}>
                        <ReactPlayer
                            url={url}
                            width='80%'
                        />
                    </div>
                ))}
            </div>

            <div>
                <div style={{backgroundColor: '#263E69', paddingTop: "10px"}}>
                    <h2 className='channelYoutube2'> รับซ่อม เกียร์ มอเตอร์ อุตสหากรรม ทุกรุ่น ทุกแบรนด์ ทุกยี่ห้อ ทุกขนาดแรงม้า </h2>
                    <img className="responsive-image" src={working} alt="working"/>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default About;
