import React from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Products.css";
import product from "./images/product.png";
import SlideShowProducts from "../Utils/SlideShow/SlideShowProducts";

const Products = () => {
    return (
        <div className="product">
            <Header />
            <div style={{ padding: '5px'}}>
                <img className="responsive-image" src={product} alt="product" />
            </div>
            <SlideShowProducts />
            <Footer />
        </div>
    );
};

export default Products;
