import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './components/Home/Home';
import About from './components/About/About';
import Services from './components/Service/Services';
import Contact from './components/Contact/Contact';
import Products from "./components/Product/Products";

const App = () => {
  return (
      <Router>
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/products" element={<Products />} />
          </Routes>
      </Router>
  );
};

export default App;
