import React from 'react';
import './Home.css';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import serviceSolution from "./image/serviceSolution.png";
import serviceCheckSure from "./image/service_check_sure.png";
import rebornMotor from "./image/rebornMotor.png";
import organizationValue from "./image/organization_value.png";
import no1 from "./image/no1.png";
import no2 from "./image/no2.png";
import SlideShow from "../Utils/SlideShow/SlideShow";

const Home = () => {
    return (
        <div className="home">
            <Header />
            <h2 className="txtHead1">Industrial services specialized for</h2>
            <h2 className="txtHead2">"Gear boxes & Motors"</h2>
            <div style={{backgroundColor: "#2C3594", paddingTop: '20px', paddingBottom: '20px'}}>
                <SlideShow />
            </div>
            <div style={{backgroundColor: "#2C3594", textAlign: 'center', paddingBottom: '10px'}}>
                    {/*<img className="responsive-image" src={gearboxMotorInfo} alt="serviceSolution" />*/}
                    <img className="responsive-image" src={no1} alt="no1" />
                    <img className="responsive-image" src={no2} alt="no2" />

                    <div className="text-center" style={{color: "#FCD405"}}> บริษัท เอดิสันเมคคานิค จำกัด </div>
                    <div className="text-indented" style={{color: "white"}}>  ก่อตั้งขึ้นเมื่อปี 2564 ดำเนินธุรกิจเกี่ยวกับ Industrial  services and consultation (งานให้บริการและให้คำปรึกษาในอุตสาหกรรม) </div>
                    <div className="text-indented" style={{color: "white"}}>  เรามีความเชี่ยวชาญในการให้บริการซ่อม จัดซื้อ จัดหาสินค้าและอะไหล่อุตสาหกรรม อาทิ เช่น เกียร์, มอเตอร์, ปั๊มอุตสาหกรรม, ไฮดรอลิกส์, อินเวอร์เตอร์ ทุกแบรนด์ ทุกประเภท ทุกยี่ห้อ ทุกขนาดแรงม้า โดยช่างมืออาชีพมากประสบการณ์ มีความรับผิดชอบ ซื่อสัตย์ต่อลูกค้า ราคายุติธรรม กล้ารับประกันผลงาน  </div>
            </div>
            <div >
                <img src={serviceSolution} alt="serviceSolution" className="responsive-image" />
            </div>

            <div>
                <img src={organizationValue} alt="organiztionValue" className="responsive-image" />
            </div>
            {/*<div className="text-center2" style={{ backgroundColor: "#2C3594", fontSize: "20px" }}>*/}
            {/*    <div style={{color: "#FCD405", fontSize: "30px"}}>ซื่อสัตย์</div>*/}
            {/*    <div style={{color: "#FCD405"}}>ปฏิบัติงานเต็มกำลังความสามารถ ด้วยความบริสุทธิ์ใจ  ไม่ทุจริตคดโกงทั้งทางตรง และทางอ้อม </div>*/}
            {/*</div>*/}
            {/*<div className="text-center2" style={{ backgroundColor: "#2C3594", fontSize: "20px" }}>*/}
            {/*    <div style={{color: "#FCD405", fontSize: "30px"}}>จริงใจ</div>*/}
            {/*    <div style={{color: "#FCD405"}}>ปฏิบัติหน้าที่ด้วยความจริงใจ ตรงไปตรงมา ไม่แสวงหาผลประโยชน์โดยมิชอบ  คำนึงถึงเกียรติและศักดิ์ศรี </div>*/}
            {/*</div>*/}
            {/*<div className="text-center2" style={{ backgroundColor: "#2C3594", fontSize: "20px" }}>*/}
            {/*    <div style={{color: "#FCD405", fontSize: "30px"}}>รับผิดชอบในงาน</div>*/}
            {/*    <div style={{color: "#FCD405"}}>กล้ารับผิดชอบในความผิดพลาด   พร้อมรับการตรวจสอบ  ไม่กล่าวโทษผู้อื่น</div>*/}
            {/*</div>*/}
            {/*<div className="text-center2" style={{ backgroundColor: "#2C3594", fontSize: "20px" }}>*/}
            {/*    <div style={{color: "#FCD405", fontSize: "30px"}}>บริการด้วยใจ</div>*/}
            {/*    <div style={{color: "#FCD405"}}>ให้บริการด้วยความจริงใจ ทัดเทียมกัน และไม่หวังผลตอบแทน โดยต้องคำนึงถึงความพึงพอใจ และประโยชน์ของผู้รับบริการ</div>*/}
            {/*</div>*/}
            <div >
                <img src={serviceCheckSure} alt="serviceCheckSure" className="responsive-image" />
                <img src={rebornMotor} alt="rebornMotor" className="responsive-image" />
            </div>
            <Footer />
        </div>
    );
};

export default Home;
