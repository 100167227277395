import React from 'react';
import Slider from 'react-slick';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './SlideShow.css'; // Create and import your custom CSS file for styling
import slide1 from '../images/products/line1_1.jpg';
import slide2 from '../images/products/line1_2.jpg';
import slide3 from '../images/products/line1_3.jpg';
import slide4 from '../images/products/line2_1.jpg';
import slide5 from '../images/products/line2_2.jpg';
import slide6 from '../images/products/line2_3.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const SlideShowProducts = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        cssEase: "linear",
    };
    return (
        <div className="slideshow-container-product">
            <h1 className="popular-products">สินค้ายอดนิยม</h1>
            <Slider {...settings}>
                <div>
                    <img src={slide1} alt="slide1" className="imgProduct" />
                </div>
                <div>
                    <img src={slide2} alt="slide2" className="imgProduct"/>
                </div>
                <div>
                    <img src={slide3} alt="slide3" className="imgProduct"/>
                </div>
                <div>
                    <img src={slide4} alt="slide4" className="imgProduct" />
                </div>
                <div>
                    <img src={slide5} alt="slide5" className="imgProduct" />
                </div>
                <div>
                    <img src={slide6} alt="slide6" className="imgProduct"/>
                </div>
            </Slider>
        </div>
        // <div>
        //     <h2>Auto Play</h2>
        //     <Slider {...settings}>
        //         <div>
        //             <h3>1</h3>
        //         </div>
        //         <div>
        //             <h3>2</h3>
        //         </div>
        //         <div>
        //             <h3>3</h3>
        //         </div>
        //         <div>
        //             <h3>4</h3>
        //         </div>
        //         <div>
        //             <h3>5</h3>
        //         </div>
        //         <div>
        //             <h3>6</h3>
        //         </div>
        //     </Slider>
        // </div>
    );
}

export default SlideShowProducts;
