import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './SlideShow.css'; // Create and import your custom CSS file for styling
import slide1 from '../images/slide1.png';
import slide2 from '../images/slide2.png';
import slide3 from '../images/slide3.png';
import slide4 from '../images/slide4.png';

const images = [slide1, slide2, slide3 ,slide4]

const SlideShow = () => {
    return (
        <div className="slideshow-container">
            <Carousel
                showArrows={true}
                autoPlay={true}
                interval={3000}
                infiniteLoop={true}
                showThumbs={false}
            >
                {images.map((image, index) => (
                    <div key={index} style={{textAlign: "center"}}>
                        <img src={image} alt={`Slide ${index + 1}`} />
                        {/*<p className="legend">Caption {index + 1}</p>*/}
                    </div>
                ))}
            </Carousel>
        </div>
    );
}

export default SlideShow;
