import React from 'react';
import './Footer.css';
// import contactUs from "./images/contact_us.png";
import contactUs2 from "./images/contact_us2.png";

const Footer = () => {
    return (
        <footer className="footer">
            {/*<div >*/}
            {/*    <img src={contactUs} alt="contactUs" className="responsive-image" />*/}
            {/*</div>*/}
            <div >
                <img src={contactUs2} alt="contactUs2" className="responsive-image" />
            </div>
            {/*<p>ติดต่อสอบถามเพิ่มเติม</p>*/}
            {/*<div className="center-column">*/}
            {/*    /!* Content for the center *!/*/}
            {/*    <p>ติดต่อสอบถามเพิ่มเติม</p>*/}
            {/*</div>*/}
            {/*<table>*/}
            {/*    <tbody>*/}
            {/*    <tr>*/}
            {/*        <td className="left-column">*/}
            {/*           logo*/}
            {/*        </td>*/}
            {/*        <td className="left-column">*/}
            {/*            <div> ติดต่องานขาย </div>*/}
            {/*            <div>edison.salesmechanic@gmail.com</div>*/}
            {/*        </td>*/}
            {/*        <td className="right-column">*/}
            {/*            /!* Content for the right column *!/*/}
            {/*            <p>Right column line 1</p>*/}
            {/*            <p>Right column line 2</p>*/}
            {/*            /!* Add more lines as needed *!/*/}
            {/*        </td>*/}
            {/*    </tr>*/}
            {/*    </tbody>*/}
            {/*</table>*/}
        </footer>
    );
};

export default Footer;
