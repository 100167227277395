import React from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import './Services.css';
import service1 from "./images/1.png";
import service2 from "./images/2.png";
import service3 from "./images/3.png";
import service4 from "./images/4.png";

const Services = () => {
    return (
        <div className='service'>
            <Header />
            <div>
                <img className="responsive-image" src={service1} alt="service1" />
                <img className="responsive-image" src={service2} alt="service2" />
                <img className="responsive-image" src={service3} alt="service3" />
                <img className="responsive-image" src={service4} alt="service4" />
            </div>
            <Footer />
        </div>
    );
};

export default Services;
